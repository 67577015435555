<template>

<app-page-outer>

	<app-page-inner class="summary">

		<div class="summary-item" v-if="!countdown && !closed">
			
			<app-icon icon="summary.countdown" class="summary-item-icon" />

			<div class="summary-item-text is-countdown" v-if="isRegistration">
				{{ remaining }} <small>day<template v-if="remaining !== 1">s</template> left to register</small>
			</div>

			<div class="summary-item-text is-countdown" v-if="!isRegistration">
				{{ remaining }} <small>day<template v-if="remaining !== 1">s</template> to go</small>
			</div>

		</div>

		<div class="summary-item">
			
			<app-icon icon="summary.calendar" class="summary-item-icon" />

			<div class="summary-item-text">
				{{ dateRange }} <small>{{ dayRange }}</small>
			</div>

		</div>

		<div class="summary-item" v-if="city || building">
			
			<app-icon icon="summary.location" class="summary-item-icon" />

			<div class="summary-item-text">
				{{ city }}<small v-if="building">{{ building }}</small>
			</div>

		</div>

		<a :href="website" target="_blank" class="summary-item" v-if="!countdown && !closed && website">
			
			<app-icon icon="summary.website" class="summary-item-icon" />

			<div class="summary-item-text">
				official website <small>click to open</small>
			</div>

		</a>

	</app-page-inner>

</app-page-outer>
	
</template>

<script>

export default {

	props: ['countdown', 'closed'],

	computed: {

		website: function() {

			return this.convention.website || this.organisation.website

		},

		building: function() {

			return this.convention.location.building || this.organisation.location.building

		},

		city: function() {

			return this.convention.location.city || this.organisation.location.city

		},

		isRegistration: function() {

			return this.$store.getters['register/page'].type === this.$constants.registration.page.type.registration

		},

		remaining: function() {

			var to = (this.isRegistration) ? this.page.to || this.convention.date.start : this.convention.date.start

			return Math.abs(this.$moment().diff(this.$moment.unix(to), 'days'))

		},

		dayRange: function() {

			var range = []

			range.push(this.$options.filters.formatDate(this.convention.date.start, 'dddd'))

			if (this.convention.date.start !== this.convention.date.end) {

				range.push(' - ')
				range.push(this.$options.filters.formatDate(this.convention.date.end, 'dddd'))

			}

			return range.join('')

		},

		dateRange: function() {

			var range = []

			range.push(this.$options.filters.formatDate(this.convention.date.start, 'DD'))

			if (this.$options.filters.formatDate(this.convention.date.start, 'MMMM') !== this.$options.filters.formatDate(this.convention.date.end, 'MMMM')) {

				range.push(' ')
				range.push(this.$options.filters.formatDate(this.convention.date.start, 'MMMM'))

			}

			if (this.convention.date.start !== this.convention.date.end) {

				range.push(' - ')
				range.push(this.$options.filters.formatDate(this.convention.date.end, 'DD'))

			}
				
			range.push(' ')
			range.push(this.$options.filters.formatDate(this.convention.date.end, 'MMMM'))

			range.push(', ')
			range.push(this.$options.filters.formatDate(this.convention.date.end, 'YYYY'))

			return range.join('')

		},

		organisation: function() {

			return this.$store.getters['register/organisation']

		},

		convention: function() {

			return this.$store.getters['register/convention']

		},

		page: function() {

			return this.$store.getters['register/page']

		}

	}
	
}

</script>

<style scoped>

.summary {
	height: 96px;
	margin-top: -96px;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.is-device .summary {
	background-color: #387DD8;
}

.is-mobile .summary {
	margin-top: 0px;
	height: auto;
	border-radius: 0px;
	flex-direction: row;
	flex-wrap: wrap;
}

.summary-item {
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: 0;
	padding: 20px;
	height: 96px;
	border-right: 1px solid rgba(0, 0, 0, 0.1);
	display: flex;
	align-items: center;
	max-width: 320px;
	background-color: #387DD8;
}

.summary-item:first-child {
	border-top-left-radius: 8px;
}

.summary-item:last-child {
	border-top-right-radius: 8px;
}

.is-mobile .summary-item:first-child,
.is-mobile .summary-item:last-child {
	border-radius: 0px;
}	

.is-mobile .summary-item {
	flex-grow: 0;
	flex-basis: initial;
	width: 50%;
	padding: 10px;
	border-right: 0px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	flex-direction: column;
}

.is-mobile .summary-item:nth-child(odd) {
	border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.summary-item-icon {
	font-size: 48px;
	color: rgba(255, 255, 255, 0.8);
	text-align: center;
	margin-right: 20px;
}

.is-mobile .summary-item-icon {
	font-size: 32px;
	width: 48px;
	text-align: center;
	margin-right: 0px;
	margin-bottom: 10px;
}

.summary-item-text {
	color: #fff;
	font-size: 18px;
	text-transform: uppercase;
	font-weight: 700;
}

.is-mobile .summary-item-text {
	font-size: 16px;
	text-align: center;
}

.summary-item-text small {
	color: rgba(255, 255, 255, 0.9);
	font-size: 14px;
	text-transform: uppercase;
	display: block;
	font-weight: 400;
}

.summary-item-text.is-countdown {
	display: flex;
	font-size: 48px;
	align-items: center;
}

.is-mobile .summary-item-text.is-countdown {
	font-size: 18px;
	flex-direction: column;
	align-items: center;
}

.summary-item-text.is-countdown small {
	line-height: 16px;
	margin-left: 10px;
}

.is-mobile .summary-item-text.is-countdown small {
	margin-left: 0px;
	font-size: 14px;
}

</style>