<template>

<app-page-outer class="blocks">

	<app-blocks :blocks="blocks" />

</app-page-outer>
	
</template>

<script>

export default {

	computed: {

		blocks: function() {

			return this.$store.getters['register/blocks']

		}

	}
	
}

</script>

<style scoped>

.blocks {
	padding: 30px 0px;
}

.is-mobile .blocks {
	padding: 10px 0px;
}

</style>