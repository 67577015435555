<template>

<div class="page" :class="{'is-preview': isPreview, 'is-fullscreen': (isClosed || !isOpen) && !isPreview}">

	<com-head v-if="!isPreview" :fullscreen="isClosed || !isOpen">
		
		<com-closed v-if="isClosed && !isPreview" />
		<com-countdown v-if="!isOpen && !isClosed && !isPreview" />
		
	</com-head>

	<com-summary v-if="!isPreview" :countdown="!isOpen" :closed="isClosed" />

	<template v-if="(!isClosed && isOpen) || isPreview">

		<com-blocks v-if="!isSuccessful" />
		
		<com-basket v-if="!isSuccessful && isSales && !isPreview" />

		<com-success v-if="isSuccessful" />

	</template>

	<com-foot :force="true" />

</div>
	
</template>

<script>

import comHead from './page/Head'
import comFoot from './../../screen/Foot'
import comSummary from './page/Summary'
import comBlocks from './page/Blocks'
import comBasket from './page/Basket'
import comSuccess from './page/Success'
import comClosed from './page/Closed'
import comCountdown from './page/Countdown'

export default {
	
	components: {

		'com-head': comHead,
		'com-summary': comSummary,
		'com-blocks': comBlocks,
		'com-foot': comFoot,
		'com-basket': comBasket,
		'com-closed': comClosed,
		'com-countdown': comCountdown,
		'com-success': comSuccess

	},

	computed: {

		page: function() {

			return this.$store.getters['register/page']

		},

		isClosed: function() {

			return this.page.to && this.$moment.unix(this.page.to).utc().unix() < this.$moment.utc().unix()

		},

		isOpen: function() {
			
			return (!this.page.from || this.$moment.unix(this.page.from).utc().unix() < this.$moment.utc().unix()) && !this.isClosed

		},

		isPreview: function() {

			return this.$store.getters['register/preview']

		},

		isRegistration: function() {

			return this.page.type === this.$constants.registration.page.type.registration

		},

		isSales: function() {

			return this.$_.contains([
				this.$constants.registration.page.type.registration,
				this.$constants.registration.page.type.thirdparty
			], this.page.type)

		},

		isSuccessful: function() {

			return this.$store.getters['register/status'] === this.$constants.registration.payment.success

		}
		
	}

}

</script>

<style scoped>

.page {
	width: 100%;
}

.is-mobile .page {
}

.page.is-fullscreen {
	padding-bottom: 0px;
}

.page.is-preview {
	padding-bottom: 0px;
	pointer-events: none;
	user-select: none;
}

</style>