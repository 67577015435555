import { render, staticRenderFns } from "./Blocks.vue?vue&type=template&id=720f2704&scoped=true&"
import script from "./Blocks.vue?vue&type=script&lang=js&"
export * from "./Blocks.vue?vue&type=script&lang=js&"
import style0 from "./Blocks.vue?vue&type=style&index=0&id=720f2704&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "720f2704",
  null
  
)

export default component.exports