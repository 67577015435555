<template>

<app-page-outer class="head" :class="{'is-fullscreen': fullscreen}">

	<div class="head-background" v-bgimage="page.design.head.image"></div>

	<app-page-inner class="head-inner">

		<div class="head-logo" v-bgimage="convention.logo || organisation.logo" />
		<div class="head-title">{{ organisation.name }}</div>
		<div class="head-subtitle">{{ convention.name }}</div>

		<div class="head-content">

			<slot></slot>

		</div>

	</app-page-inner>

</app-page-outer>
	
</template>

<script>

export default {

	props: ['fullscreen'],

	computed: {

		convention: function() {

			return this.$store.getters['register/convention']

		},

		organisation: function() {

			return this.$store.getters['register/organisation']

		},

		page: function() {

			return this.$store.getters['register/page']

		}

	}
	
}

</script>

<style scoped>

.head {
	min-height: 560px;
	padding: 0px 0px 96px 0px;
}

.head.is-fullscreen {
	min-height: calc(100vh - var(--vh-offset, 0px));
}

.is-mobile .head {
	min-height: calc(100vh - var(--vh-offset, 0px) - 192px);
	padding: 0px;
}

.head-background {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: #2F3A4A;
	filter: sepia(100%) hue-rotate(190deg) saturate(400%) brightness(50%);
}

.head-inner {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.head-logo {
	background-size: contain;
	width: 120px;
	height: 120px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-color: #fff;
	border-radius: 8px;
	border: 10px solid #fff;
	margin-bottom: 20px;
}

.is-mobile .head-logo {
	width: 96px;
	height: 96px;
}

.head-title {
	font-size: 64px;
	font-weight: 700;
	letter-spacing: 2px;
	color: #fff;
	text-transform: uppercase;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.is-mobile .head-title {
	font-size: 32px;
	text-align: center;
}

.head-subtitle {
	font-size: 48px;
	font-weight: 500;
	letter-spacing: 2px;
	color: #fff;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.is-mobile .head-subtitle {
	font-size: 32px;
	margin-top: 10px;
	text-align: center;
}

.head-content {
	width: 100%;
}

</style>