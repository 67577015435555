<template>

<app-page-outer>

	<app-page-inner class="closed">

		<com-text :item="{ content: page.message.close }" />

	</app-page-inner>

</app-page-outer>
		
</template>

<script>

import comText from './Text'

export default {

	components: {
		comText
	},

	computed: {

		page: function() {

			return this.$store.getters['register/page']

		}
		
	}

}

</script>

<style scoped>

.closed {
	padding-top: 40px;
}

.closed >>> * {
	color: #fff!important;
	text-align: center;
	font-weight: 500;
}

</style>