<template>

<app-page-outer class="success">

	<app-page-inner v-if="isRegistration">

		<div ref="inner" class="success-text" v-html="page.message.success" />

		<com-heading text="What happens next" />

		<div class="success-next">

			<div class="success-next-item">
				<app-icon icon="receipt" />
				<p>A receipt of the purchase will be e-mailed to the primary badge holder shortly. The purchase reference number is <b>#{{ reference }}</b>.</p>
			</div>

			<div class="success-next-item">
				<app-icon icon="badge" />
				<p>Each badge holder will receive an e-mail confirming their individual badge details within the next few minutes.</p>
			</div>

			<div class="success-next-item">
				<app-icon icon="key" />
				<p>Anyone new to Cardboard Events will also receive their login credentials so they can manage their own badge.</p>
			</div>

		</div>

	</app-page-inner>

	<app-page-inner v-if="isThirdparty">

		<div ref="inner" class="success-text" v-html="page.message.success" />

		<com-heading text="What happens next" />

		<div class="success-next">

			<div class="success-next-item">
				<app-icon icon="receipt" />
				<p>A receipt of the purchase will be e-mailed to you shortly. The purchase reference number is <b>#{{ reference }}</b>.</p>
			</div>

			<div class="success-next-item">
				<app-icon icon="key" />
				<p>If you're new to Cardboard Events you will also receive login credentials so you can review your purchase.</p>
			</div>

		</div>

	</app-page-inner>

	<app-page-inner v-if="!isRegistration && !isThirdparty">

		<div ref="inner" class="success-text" v-html="page.message.success" />

	</app-page-inner>

</app-page-outer>
		
</template>

<script>

import JSConfetti from 'js-confetti'
import comHeading from './Heading'

export default {

	components: {
		'com-heading': comHeading
	},

	data: function() {

		return {
			confetti: false
		}

	},

	created: function() {

		if (this.isRegistration) this.$nextTick(function() {

			window.scrollTo(0, this.$refs.inner.getBoundingClientRect().top)

			this.confetti = new JSConfetti()

			this.confetti.addConfetti({
				confettiRadius: 3,
				confettiNumber: 500,
			})

		}.bind(this))

	},

	computed: {

		isRegistration: function() {

			return this.$store.getters['register/page'].type === this.$constants.registration.page.type.registration

		},

		isThirdparty: function() {

			return this.$store.getters['register/page'].type === this.$constants.registration.page.type.thirdparty

		},

		reference: function() {

			return this.$store.getters['register/reference']

		},

		page: function() {

			return this.$store.getters['register/page']

		}
		
	}

}

</script>

<style scoped>

.is-mobile .success {
	padding: 0px 10px;
}

.success-text {
	padding: 80px 0px 90px 0px;
	text-align: center;
	font-size: 32px;
	color: #343434;
}

.is-mobile .success-text {
	padding: 40px 0px 50px 0px;
}

.success-next {
	display: flex;
	justify-content: center;
}

.is-mobile .success-next {
	flex-direction: column;
}

.success-next-item {
	width: 25%;
	padding: 0px 20px;
	text-align: center;
}

.is-mobile .success-next-item {
	width: 100%;
	margin-bottom: 20px;
}

.success-next-item >>> .icon {
	font-size: 64px;
	color: #387DD8;
	height: 80px;
	line-height: 80px;
}

.success-next-item p {
	font-size: 16px;
	color: #343434;
	line-height: 20px;
	margin-top: 20px;
}

</style>