<template>

<app-page-outer>

	<app-page-inner class="countdown">

		<com-text :item="{ content: page.message.open }" />

		<div class="countdown-widget">
			
			<flip-countdown :deadline="countdown" />

		</div>

	</app-page-inner>

</app-page-outer>
		
</template>

<script>

import FlipCountdown from 'vue2-flip-countdown'
import comText from './Text'

export default {

	components: {
		comText,
		FlipCountdown
	},

	computed: {

		countdown: function() {

			return this.$moment.unix(this.page.from).format('YYYY-MM-DD HH:mm:ss')

		},

		page: function() {

			return this.$store.getters['register/page']

		}
		
	}

}

</script>

<style scoped>

.countdown {
	padding-top: 40px;
	text-align: center;
}

.countdown-widget {
	display: flex;
	justify-content: center;
	margin: 40px 0px;
}

.countdown-widget >>> .flip-clock {
	margin: 0px;
}

.countdown-widget >>> * {
	color: #fff!important;
}

.countdown-widget >>> .flip-card__top,
.countdown-widget >>> .flip-card__back-bottom,
.countdown-widget >>> .flip-card__bottom,
.countdown-widget >>> .flip-card__back::after,
.countdown-widget >>> .flip-card__back::before {
	color: #fff!important;
}

.countdown >>> * {
	color: #fff!important;
	text-align: center;
}

.countdown h1,
.countdown >>> h1 {
	color: #fff!important;
}

</style>